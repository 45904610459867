mat-icon {
  width: 40px !important;
  height: 40px !important;

  &.big {
    width: 48px !important;
    height: 48px !important;
  }

  &.technology {
    width: 64px !important;
    height: 64px !important;
  }

  &.steps-icon {
    width: 78px !important;
    height: 78px !important;
  }

  &.small {
    width: 24px !important;
    height: 24px !important;
  }
  &.navbar {
    width: 32px !important;
    height: 32px !important;
  }

  &.logo {
    width: 91px !important;
    height: 37px !important;
  }

  &.rotate {
    transform: rotate(180deg);
  }
}

@import "./theme-variables.scss";

ul.custom-services {
  list-style-type: square;

  .ul-header-services {
    margin: 16px 0;
  }

  li {
    color: $primary-yellow;
    margin-left: 20px;

    &::marker {
      font-size: 30px;
    }

    &.li-services {
      height: 35px;
    }

    &.li-big {
      height: 60px;
    }

    p {
      color: $secondary-blue-700;
    }
  }
}

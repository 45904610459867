.border-custom {
  border-top: 4px solid;
  border-right: 16px solid;
  border-bottom: 16px solid;

  &.small {
    border-top: 4px solid;
    border-right: 8px solid;
    border-bottom: 8px solid;
    clip-path: polygon(0 0, 98.5% 0, 100% 1.5%, 100% 100%, 1.5% 100%, 0 97%);
  }

  &.middle {
    clip-path: polygon(0 0, 98% 0, 100% 3%, 100% 100%, 2.5% 100%, 0 96.5%);
  }

  &.big {
    clip-path: polygon(0 0, 99% 0, 100% 3%, 100% 100%, 1.5% 100%, 0 97%);
  }
}

@media screen and (max-width: 500px) {
  .border-custom {
    border-top: 2px solid;
    border-right: 6px solid;
    border-bottom: 6px solid;

    &.small {
      border-top: 2px solid;
      border-right: 6px solid;
      border-bottom: 6px solid;
    }

    &.middle {
      clip-path: polygon(0 0, 99% 0, 100% 2%, 100% 100%, 1.5% 100%, 0 98.5%);
    }

    &.big {
      clip-path: polygon(0 0, 99% 0, 100% 1%, 100% 100%, 1.5% 100%, 0 97%);
    }
  }
}

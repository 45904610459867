@import "./theme-variables.scss";

h1 {
  font-family: Geologica, "cursive";
  font-size: 104px;
  font-weight: 700;
  line-height: 104px;
  letter-spacing: -0.035em;
  text-align: left;
  color: $secondary-blue-800;
  pointer-events: none;
  user-select: none;
}

h2,
h2 > span {
  font-family: Geologica, "cursive";
  font-size: 48px;
  font-weight: 600;
  line-height: 57.6px;
  letter-spacing: -0.035em;
  text-align: left;
  color: $secondary-blue-800;
  pointer-events: none;
  user-select: none;

  span {
    background-color: $primary-yellow;
    padding: 0 8px 0 8px;
    color: $secondary-blue-800;
  }
}

h3,
h3 > span {
  font-family: Geologica, "cursive";
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -0.035em;
  text-align: left;
  color: $secondary-blue-800;
  pointer-events: none;
  user-select: none;

  span {
    background-color: $primary-yellow;
    padding: 0 8px 0 8px;
    color: $secondary-blue-800;
  }
}

h4 {
  font-family: Geologica, "cursive";
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: $secondary-blue-800;
}

h5 {
  font-family: Geologica, "cursive";
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.011em;
  text-align: left;
  color: $secondary-blue-800;
}

p {
  font-family: Geologica, "cursive";
  line-height: 27px;
  text-align: left;
  color: $secondary-blue-800;

  &.m {
    font-size: 16px;
  }

  &.l {
    font-size: 18px;
  }

  &.normal {
    font-weight: 400;
  }

  &.bold {
    font-weight: 600;
  }
}

.font-error {
  font-size: 14px;
}

.header-title-privacy {
  font-size: 90px;
}

@media screen and (max-width: 1000px) {
  .header-title-privacy {
    font-size: 48px;
  }
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 48px;
  }

  h2,
  h2 > span {
    font-size: 32px;
  }

  h3,
  h3 > span {
    font-size: 28px;
    line-height: 40px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 18px;
  }

  .header-title-privacy {
    font-size: 30px;
  }
}

@import "./theme-variables.scss";
.ngx-pagination {
  padding-top: 80px;
  .current {
    padding: 0 !important;
    background: $primary-yellow !important;
    width: 43px;
    height: 41px;
    line-height: 2.2;
    text-align: center;
    .pagination-previous {
      span::before {
        content: "qwe";
        display: none;
      }
    }
    span {
      color: $secondary-blue-900;
    }
  }

  .pagination-previous {
    span::before {
      content: "qwe";
      display: none;
    }
  }

  .pagination-previous.disabled:before {
    content: "" !important;
  }
  .pagination-next.disabled:after {
    content: "" !important;
  }
}

$primary-yellow: #ffbf00;

$secondary-blue-900: #07121f;
$secondary-blue-800: #0c1e34;
$secondary-blue-700: #2b353f;
$secondary-blue-600: #4e5a67;
$secondary-blue-500: #6f7a89;
$secondary-blue-300: #c4cdd8;

$secondary-biege-400: #e0cfb7;
$secondary-biege-300: #ece6d5;
$secondary-biege-200: #f1eee5;
$secondary-biege-100: #faf8f5;

$secondary-white: #ffffff;

$tertiary-red-700: #663030;
$tertiary-red-400: #985151;
$tertiary-red-200: #f2dbdb;

$tertiary-olive-700: #b0a11a;
$tertiary-olive-400: #cdc747;
$tertiary-olive-200: #edecd5;

$tertiary-orange-700: #b0471a;
$tertiary-orange-400: #cd6f47;
$tertiary-orange-200: #edddd5;

$tertiary-pink-700: #66313c;
$tertiary-pink-400: #995160;
$tertiary-pink-200: #edd5da;

$tertiary-cobalt-700: #314566;
$tertiary-cobalt-400: #516c99;
$tertiary-cobalt-200: #d5deed;

$tertiary-green-700: #306649;
$tertiary-green-400: #519872;
$tertiary-green-200: #d3ecdf;

$tertiary-error: #913636;

.all-projects {
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 22px;
}

.slider {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  gap: 50px;

  .action__slider {
    display: flex;
    justify-content: end;
    gap: 32px;

    button {
      height: 48px;
      width: 48px;

      mat-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .all-projects {
    width: 420px;
    flex: 2;
  }
  .slider {
    .action__slider {
      flex: none;
    }
  }
}

@media screen and (max-width: 500px) {
  .all-projects {
    width: 100%;
  }
  .slider {
    flex-direction: row;
  }
}

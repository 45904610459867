@import "./theme-variables.scss";

button {
  cursor: pointer;
}
.button-primary {
  border: 2px solid $primary-yellow;
  background-color: transparent;
  display: flex;
  gap: 8px;
  align-items: center;

  &:hover {
    background-color: $primary-yellow;
  }

  &:focus {
    background-color: $primary-yellow;
  }

  &:disabled {
    background-color: $secondary-blue-300;
    border-color: transparent;
    pointer-events: none;
  }

  &.s {
    padding: 8px 16px 8px 16px;
    margin: 0 0 7px 5px;
  }

  &.l {
    padding: 16px 24px 16px 24px;
  }

  &.m {
    padding: 8px 16px 8px 16px;
    width: max-content;
  }
}

.button-secondary {
  padding: 8px 16px 8px 16px;
  border-color: transparent;
  background-color: transparent;

  &:hover {
    background-color: $secondary-biege-400;
  }

  &:focus {
    background-color: $secondary-biege-400;
  }

  &:disabled {
    color: $secondary-blue-300;
    background-color: transparent;
  }
}

.button-link {
  padding: 4px 4px 4px 8px;
  border-color: transparent;
  background-color: transparent;
  border-bottom: 2px solid $primary-yellow;
  display: flex;
  align-items: center;
  gap: 9px;

  &:hover {
    border: 2px solid $primary-yellow;
    background-color: $primary-yellow;
  }

  &:focus {
    border: 2px solid $primary-yellow;
    background-color: $primary-yellow;
  }

  &:disabled {
    color: $secondary-blue-300;
    border-color: transparent;
    background-color: transparent;
  }
}

.button-filer {
  padding: 8px;
  border-color: transparent;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
  line-height: 33.6px;
  letter-spacing: -0.02em;
  text-align: left;

  &:disabled {
    border: 2px solid transparent;
    background-color: transparent;
    color: $secondary-blue-300;
  }
}

.button-filtration {
  padding: 8px;
  border-color: transparent;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
  line-height: 33.6px;
  letter-spacing: -0.02em;
  text-align: left;

  &.selected {
    border-bottom: 2px solid $primary-yellow;
  }

  &:focus {
    border-bottom: 2px solid $primary-yellow;
  }

  &:active {
    border-bottom: 2px solid $primary-yellow;
  }

  &:disabled {
    border: 2px solid transparent;
    background-color: transparent;
    color: $secondary-blue-300;
  }
}

.button-icon {
  height: 56px;
  padding: 6px;
  background-color: $primary-yellow;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: $secondary-biege-200;
    cursor: auto;
  }
}


@media screen and (max-width: 800px) {
  .button-link {
    position: relative;
    z-index: 10;
  }
}

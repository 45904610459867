@import "./theme-variables.scss";

ul.custom {
  list-style: none;
  padding-left: 0;

  .ul-header {
    margin: 16px 0;
  }

  li {
    color: $primary-yellow;
    position: relative;
    padding-left: 0.6rem;
    display: flex;
    align-items: center;

    &::before {
      content: "\25A0";
      position: absolute;
      left: 0;
      top: 13px;
      font-size: 20px;
      color: $primary-yellow;
    }

    &.li-navbar {
      width: 100%;
      height: 35px;
    }

    &.li-big {
      height: 60px;
    }

    p {
      color: $secondary-blue-700;
    }

    .service-custom {
      padding: 15px 0 0 10px;
    }
  }
}

@import "./theme-fonts.scss";
@import "./theme-button.scss";
@import "./theme-icons.scss";
@import "./theme-header.scss";
@import "./theme-list.scss";
@import "./theme-list-services.scss";
@import "./theme-paginator.scss";
@import "./theme-border-custom.scss";
@import "swiper/css";
@import "swiper/css/navigation";
@import "./theme-variables.scss";
@import "./theme-slider-header.scss";

* {
  font-family: Geologica;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &[ng-reflect-router-link] {
    color: $secondary-blue-800;
  }
}

.button-link,
.button-primary {
  color: inherit !important;
}

a {
  text-decoration: none;
}

body {
  width: 100vw;
  overflow-x: hidden !important;
  background-color: $secondary-biege-100;
}

:root {
  --blue: rgba(81, 108, 153, 1);
  --orange: rgba(205, 111, 71, 1);
  --green: rgba(81, 152, 114, 1);
  --red: rgba(152, 81, 81, 1);
  --olive: rgba(205, 199, 71, 1);
}

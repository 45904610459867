.header-main {
  max-width: 1440px;
  display: flex;
  align-items: end;
  position: relative;
  padding: 20px 0 40px 0;
  border-bottom: 2px solid $primary-yellow;

  &__text {
    flex: 8;
    display: flex;
    flex-direction: column;
    gap: 24px;

    h2 {
      line-height: 1.4;
    }

    p {
      max-width: 500px;
    }
  }

  .background {
    flex: 8;
    background-image: url("/assets/five-back-cubes.svg");
    background-position: 100%;
    background-repeat: no-repeat;
    height: 470px;
    display: flex;
    justify-content: end;
    align-items: end;

    h1 {
      text-align: end;
    }
  }
}

@media screen and (max-width: 1000px) {
  .header-main {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;

    &__text {
      p {
        max-width: none;
      }
    }

    .background {
      justify-content: start;
      padding-top: 250px;
      background-size: contain;
      width: 100%;

      h1 {
        text-align: start;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .header-main {
    gap: 40px;

    &__text {
      gap: 20px;

      p {
        gap: 20px;
        margin-bottom: 0px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .header-main {
    &__text {
      p {
        max-width: 500px;
      }
    }
  }
}
